import React from "react"
import PropTypes from "prop-types"
import Loader from 'react-loader-spinner'

import Cookies from 'js-cookie'
// import "./multistep_form.js";
import consumer from "channels/consumer";
import sha256 from 'crypto-js/sha256';

import LoanCard from './LoanCard';
import DebtTypeButtonList, { ICONS } from './DebtTypeButtonList'
import LogoImg from 'images/logo.png'

import Loan from "./models/Loan";

const hexHash = (msg) => sha256(msg);

import I18n from 'packs/i18n/translations';
I18n.translations || (I18n.translations = {});

let parse = require('html-react-parser');
let formDataToMap = (data) => {
  return Array.from(data.entries()).reduce(function(map, obj) {
    map[obj[0]] = obj[1];
    return map;
  }, {});
};

class PayoffPlan extends React.Component {
  constructor(props) {
    super(props);
    this.dataKey = 'debtFreeStorage';
    let preferences = this.loanPreferences();

    this.state = {
      // TODO: fix when added language selector
      // lang: Cookies.get('locale') || 'nb',
      lang: 'nb',
      successAlert: null,
      dangerAlert: null,
      loans: this.loadLoans(),
      autoOnboarding: true,
      bankid_reference_code: null,
      bankid_phone: '',
      bankid_birth_date: '',
      bankidLoading: false,
      count_from: preferences.count_from,
      extra_payment: preferences.extra_payment,
      multiStep: 0,
    };

    this.handleLangChange = this.handleLangChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBankIDSubmit = this.handleBankIDSubmit.bind(this);
    this.addLoan = this.addLoan.bind(this);
    this.updateLoan = this.updateLoan.bind(this);
    this.deleteLoan = this.deleteLoan.bind(this);
    this.generatePlan = this.generatePlan.bind(this);
    this.handlePreferencesChange = this.handlePreferencesChange.bind(this);
    this.handleManualLoanSubmit = this.handleManualLoanSubmit.bind(this);

    this.nextMFStep = this.nextMFStep.bind(this);
    this.prevMFStep = this.prevMFStep.bind(this);
    this.handleMFInputChange = this.handleMFInputChange.bind(this);

    this.bankIDPhoneInput = React.createRef();
    this.bankIDBirthDateInput = React.createRef();
    this.debtTypeSelect = React.createRef();
  }

  componentDidMount() {
    // if (this.showOnboarding()) {
    //   setTimeout(() => {
    //     this.bankIDPhoneInput.current.focus();
    //   }, 100);
    // }
  }

  t (transaction) {
    return I18n.t(`payoff_plans.${transaction}`);
  }

  showOnboarding () {
    return !this.state.loans || this.state.loans.length == 0;
  }

  // Data structure: Object
  //   createdAt: Number
  //   updatedAt:Number
  //   loans: Array [Loan]
  loadLoans () {
    const storageDataJSON = localStorage.getItem(this.dataKey),
          storageData = storageDataJSON && JSON.parse(storageDataJSON);

    if (storageData && storageData.loans) {
      return storageData.loans.map(loan => new Loan(loan));
    } else {
      localStorage.setItem(this.dataKey, JSON.stringify({ createdAt: (new Date()).getTime() }));

      return null;
    }
  }

  loanPreferences () {
    const storageDataJSON = localStorage.getItem(this.dataKey);
    if (!storageDataJSON) { return {}; }

    const storageData = JSON.parse(storageDataJSON);
    return {
      extra_payment: storageData.extra_payment,
      count_from: storageData.count_from,
    }
  }

  addLoan (loan) {
    return this.addLoans([loan]);
  }

  // Expect to get Array, always
  addLoans(loans) {
    if (loans.every(loan => loan.isValid())) {
      this.setState({ loans: [...(this.state.loans || []), ...loans] }, this.saveLoans);

      return true;
    } else {
      // alert(`Something wrong with: ${JSON.stringify(loans)}`);

      return false;
    }
  }

  // Single loan info update
  updateLoan(loan) {
    if (!loan.isValid()) { return false; }

    let loans = this.state.loans;
    loans[loan.id] = loan;
    console.log(loans);

    this.setState({ loans: loans }, this.saveLoans);

    return true;
  }

  deleteLoan(idx) {
    let loans = this.state.loans;
    loans.splice(idx, 1);

    this.setState({ loans: loans }, this.saveLoans);

    return true
  }

  saveLoans () {
    let storage = JSON.parse(localStorage.getItem(this.dataKey));
    storage.loans = this.state.loans;
    storage.updatedAt = (new Date()).getTime();

    localStorage.setItem(this.dataKey, JSON.stringify(storage));
  };

  savePreferences () {
    let storage = JSON.parse(localStorage.getItem(this.dataKey));
    storage.extra_payment = this.state.extra_payment;
    storage.count_from = this.state.count_from;
    storage.updatedAt = (new Date()).getTime();

    localStorage.setItem(this.dataKey, JSON.stringify(storage));
  }

  handleLangChange (event) {
    Cookies.set('locale', event.target.value);
    this.setState({ lang: event.target.value });
  }

  handleInputChange (event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name == 'bankid_phone' && value.length == 8) { this.bankIDBirthDateInput.current.focus() }

    this.setState({
      [name]: value
    });
  }

  handlePreferencesChange (event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    switch(name) {
      case 'extra_payment':
        value = Number(value);
        break;
      default:
        value = value;
    }

    this.setState({
      [name]: value
    }, this.savePreferences);
  }

  handleMFInputChange (event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let mfInputs = this.state.firstLoanMF || {};
    mfInputs[name] = value;

    this.setState({ firstLoanMF: mfInputs });
  }

  handleBankIDSubmit (event) {
    this.setState({ bankidLoading: true });
    event.preventDefault();
    let that = this;

    // Wait for reference code
    let bankIdSubscription =
      consumer.subscriptions.create({ channel: "BankIdChannel", id: String(hexHash(`${this.state.bankid_birth_date}_${this.state.bankid_phone}`)) }, {
        received(data) {
          that.setState({ bankid_reference_code: data.body, bankidLoading: false });
        }
      });

    fetch('/payoff_plans/debt_info', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        authenticity_token: this.props.formAuthenticityToken,
        payoff_plan: {
          phone: this.state.bankid_phone,
          birth_date: this.state.bankid_birth_date,
        }
      })
    }).then(respJSON => {
      // consumer.subscriptions.remove(bankIdSubscription);
      bankIdSubscription.unsubscribe();
      consumer.disconnect();

      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }
      // console.log(respJSON);

      return respJSON.json();
    })
    .then(resp => {
      if (resp.error_message) {
        throw Error(resp.error_message);
      }

      // Loan item sample:
      // "loan": {
      //     "type": "creditFacility",
      //     "timestamp": "2021-06-25T03:25:03Z",
      //     "coBorrower": 0,
      //     "creditLimit": 1000000,
      //     "installmentCharges": 4500,
      //     "nominalInterestRate": 0,
      //     "interestBearingBalance": 0,
      //     "installmentChargePeriod": "MONTHLY",
      //     "nonInterestBearingBalance": 0
      //   },
      //   "creditorName": "RESURS BANK AB"
      // const loans = resp.list.filter((item) => item.type === "creditFacility")[0].loans;
      const loans = resp;
      // console.log(loans);

      this.addLoans(loans.map(loan => {
        let l = new Loan();
        l.parse(loan);
        // console.log(l);

        return l;
      }));
      // loans.map((loan) => {
      //   const currentBalance = (Number(loan.interestBearingBalance) + Number(loan.nonInterestBearingBalance)) / 100;

      //   addOrUpdateLoan({
      //     debt_type: 'credit_card',
      //     name: loan.financialInstitutionName,
      //     current_balance: currentBalance,
      //     interest_rate: Number(loan.nominalInterestRate) / 100,
      //     minimum_payment: Math.max(0.03 * currentBalance, 350),
      //   })
      // })

      this.setState({ successAlert: this.t('new.first_loans_added_alert'), bankid_reference_code: null });
    })
    .catch(error => alert(error));
  }

  handleManualLoanSubmit (event) {
    event.preventDefault();

    if (this.addLoan(new Loan(this.state.firstLoanMF))) {
      this.setState({ successAlert: this.t('new.first_loan_created_alert') })
    } else {
      this.setState({ dangerAlert: this.t('new.errors.field_missed') })
    };
  }

  nextMFStep (event) {
    event.preventDefault();

    this.setState({ multiStep: this.state.multiStep + 1 })
  }

  prevMFStep (event) {
    event.preventDefault();

    this.setState({ multiStep: this.state.multiStep - 1 })
  }

  generatePlan (event) {
    event.preventDefault();

    fetch('/payoff_plans/generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        authenticity_token: this.props.formAuthenticityToken,
        extra_payment: this.state.extra_payment,
        count_from: this.state.count_from,
        loans: this.state.loans.map(loan => {
          return({
            current_balance: loan.totalBalance,
            interest_rate: loan.interestRate,
            minimum_payment: loan.payment,
            name: loan.name,
          })
        })
      }),
    }).then((resp) => {
      if (!resp.ok) {
        if (resp.status == 422) {
          throw Error(I18n.t('payoff_plans.new.errors.infeasible_data'));
        } else {
          throw Error(resp.statusText);
        };
      };

      return resp.json();
    }).then((response) => {
      location.href = response.url;
    }).catch(function(error) {
      alert(error.message);
    });
  }

  renderHeader () {
    return (
      <header className="topbar" data-navbarbg="skin6">
        <nav className="navbar top-navbar navbar-expand-md">
          <div className="container-fluid">
            <div className="navbar-header" data-logobg="skin6">
              <a className="nav-toggler waves-effect waves-light d-block d-md-none" />
              <div className="navbar-brand">
                <a>
                  <b className="logo-icon">
                    <img src={ LogoImg } className="dark-logo" />
                    <img src={ LogoImg } className="light-logo" />
                  </b>
                  <span className="logo-text">
                    <img src={ LogoImg } className="dark-logo" />
                    <img src={ LogoImg } className="light-logo" />
                  </span>
                </a>
                <ul className="navbar-nav float-left mr-auto ml-3 pl-1">
                  <li className="nav-item">
                    <a className="nav-link">
                      <div className="customize-input">
                        <select id="lang_select" className="custom-select form-control bg-white custom-radius custom-shadow border-0" defaultValue={ this.state.lang } onChange={ this.handleLangChange }>
                          <option value="en"> EN </option>
                          <option value="nb"> NO </option>
                        </select>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <a className="topbartoggler d-block d-md-none waves-effect waves-light" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" data-target="#navbarSupportedContent" data-toggle="collapse" />
            </div>
          </div>
        </nav>
      </header>
    )
  }

  renderAlerts () {
    return (
      <React.Fragment>
        {
          this.state.successAlert &&
          <div className="alert alert-success alert-dismissible bg-success text-white border-0 fade show" role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true"> &times; </span>
            </button>
            <span dangerouslySetInnerHTML={{ __html: this.state.successAlert }} />
          </div>
        }
        {
          this.state.dangerAlert &&
          <div className="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show" role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true"> &times; </span>
            </button>
           <span dangerouslySetInnerHTML={{ __html: this.state.dangerAlert }} />
          </div>
        }
      </React.Fragment>
    )
  }

  renderBankID () {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">BankID på Mobil</div>
          <div className="card-body">
            {
              this.state.bankidLoading &&
                <div className="bankid-loading d-flex">
                  <div className="align-self-center d-flex">
                    <div><Loader type="Bars" color="#2e6cc6" height={40} width={80} /></div>
                    <div className="d-flex align-items-center light-blue text-fit-2">{ this.t('first_time_form.step0.loading') }</div>
                  </div>
                </div> ||
                this.state.bankid_reference_code &&
                  <div className="bankid-loading d-flex light-blue">
                    <div className="align-self-center d-flex">
                      <i className="fa-2x fas fa-mobile-alt mr-2 align-self-center"></i>
                      <div>
                        { this.t('first_time_form.step0.bankid_reference_code') }:
                        <strong className="ml-1 font-weight-bold">{ this.state.bankid_reference_code }</strong>
                        <div>{ this.t('first_time_form.step0.bankid_reference_code_hint') }</div>
                      </div>
                    </div>
                  </div> ||
                <form onSubmit={this.handleBankIDSubmit}>
                  <div className="form-group">
                    <label>{ this.t('first_time_form.step0.bankid_phone') }</label>
                      <div className="input-group">
                        <input autoFocus className="form-control text-input text-fit-2" data-min-length="8" name="bankid_phone" type="number" value={this.state.bankid_phone} onChange={this.handleInputChange} ref={ this.bankIDPhoneInput } />
                      </div>
                    </div>
                  <div className="form-group">
                    <label>{ this.t('first_time_form.step0.bankid_birth_date') }</label>
                    <div className="input-group">
                      <input className="form-control text-input text-fit-2" data-min-length="8" name="bankid_birth_date" type="number" value={this.state.bankid_birth_date} onChange={this.handleInputChange} ref={ this.bankIDBirthDateInput } />
                    </div>
                  </div>
                  <input type="submit" value={ this.t('first_time_form.step0.bankid_collect') } className="btn btn-outline-primary btn-block ml-auto text-fit-2" />
                  <div className="text-muted text-center">{ parse(this.t('first_time_form.step1.next_button_hint')) }</div>
                </form>
            }
          </div>
        </div>
        <a className="text-reset text-decoration-none" href="#" onClick={ (e) => { e.preventDefault(); this.setState({ autoOnboarding: false, multiStep: 1 }) } }>{ this.t('first_time_form.step0.manual_process') }</a>
      </React.Fragment>
    )
  }

  renderOnboarding () {
    return (
      <React.Fragment>
        <div className="d-flex" style={{ minHeight: '80vh' }}>
          <div className="row align-self-center w-100">
            <div className="col-md-6 mb-4 mb-md-0 d-flex align-items-center">
              <div>
                <div className="big-header"> { this.t('first_time_form.header') } </div>
                <div className="text-fit-3">{ parse(this.t(`first_time_form.step${this.state.multiStep}.header`)) }</div>
                <div className="text-fit-2">{ parse(this.t(`first_time_form.step${this.state.multiStep}.description`)) }</div>
              </div>
            </div>
            <div className="col-md-6">
              { this.state.autoOnboarding && this.renderBankID() || this.renderMultistepForm() }
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderMultistepForm () {
    const nextStepLink = <button onClick={ this.nextMFStep } className="btn btn-outline-primary btn-block ml-auto text-fit-2">{ this.t('first_time_form.step1.next_button') }</button>;
    const prevStepLink = <a className="text-reset text-decoration-none" href="#" onClick={ this.prevMFStep }>{ this.t('first_time_form.step3.back_button') }</a>
    let stepInputs;

    switch(this.state.multiStep) {
      case 1:
        stepInputs = <React.Fragment>
          <div className="form-group">
            <DebtTypeButtonList cardClass="col-md-6 col-lg-4 col-6" onChange={ () => { this.handleMFInputChange({ target: { value: this.debtTypeSelect.current.state.selected, name: 'debtType' } }); this.setState({ showMFName: true }); } } ref={ this.debtTypeSelect } />
          </div>
          {
            this.state.showMFName &&
              <React.Fragment>
                <div className="form-group">
                  <label> { this.t('first_time_form.step1.name_label') }</label>
                  <input className="form-control text-input text-fit-2" type="text" name="name" value={ this.state.firstLoanMF?.name || '' } onChange={ this.handleMFInputChange } placeholder={ this.t('first_time_form.step1.name_placeholder') } required={ true } data-min-length="3" />
                </div>
                <div className="form-group">
                  { nextStepLink }
                  <div className="text-muted text-center">{ parse(this.t('first_time_form.step1.next_button_hint')) }</div>
                </div>
              </React.Fragment>
          }
        </React.Fragment>;
        break;
      case 2:
        stepInputs = <React.Fragment>
          <div className="form-group">
            <label> { this.t('first_time_form.step2.balance_label') }</label>
            <div className="input-group">
              <input className="form-control text-input text-fit-2" type="number" step="any" name="totalBalance" value={ this.state.firstLoanMF?.totalBalance || '' } onChange={ this.handleMFInputChange } placeholder={ this.t('first_time_form.step2.balance_placeholder') } required={ true } />
              <span className="text-fit-2 align-self-center ml-2">kr</span>
            </div>
          </div>
          <div className="form-group">
            { nextStepLink }
          </div>
        </React.Fragment>;
        break;
      case 3:
        stepInputs = <React.Fragment>
          <div className="form-group">
            <label> { this.t('first_time_form.step3.interest_rate_label') }</label>
            <div className="input-group">
              <input className="form-control text-input text-fit-2" type="number" step="any" name="interestRate" value={ this.state.firstLoanMF?.interestRate || '' } onChange={ this.handleMFInputChange } placeholder={ this.t('first_time_form.step3.interest_rate_placeholder') } required={ true } />
              <span className="text-fit-2 align-self-center ml-2">%</span>
            </div>
          </div>
          <div className="form-group">
            { nextStepLink }
          </div>
        </React.Fragment>;
        break;
      case 4:
        stepInputs = <React.Fragment>
          <div className="form-group">
            <label> { this.t('first_time_form.step4.monthly_payment_label') }</label>
            <div className="input-group">
              <input className="form-control text-input text-fit-2" type="number" step="any" name="payment" value={ this.state.firstLoanMF?.payment || '' } onChange={ this.handleMFInputChange } placeholder={ this.t('first_time_form.step4.monthly_payment_placeholder') } required={ true } />
              <span className="text-fit-2 align-self-center ml-2">kr</span>
            </div>
          </div>
          <div className="form-group">
            <input type="submit" value={ this.t('first_time_form.step4.next_button') } className="btn btn-outline-primary btn-block ml-auto text-fit-2" />
          </div>
        </React.Fragment>;
        break;
    }

    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">{ this.state.firstLoanMF?.name || 'Loan' }</div>
          <div className="card-body">
            <form onSubmit={this.handleManualLoanSubmit}>
              { stepInputs }
            </form>
          </div>
        </div>
        {
          this.state.multiStep == 1 &&
            <a className="text-reset text-decoration-none" href="#" onClick={ (e) => { e.preventDefault(); this.setState({ autoOnboarding: true, multiStep: 0, showMFName: false }) } }>{ this.t('first_time_form.step0.auto_processs') }</a> ||
            prevStepLink
        }
      </React.Fragment>
    )
  }

  renderLoan(loan, idx) {
    loan.id = idx;

    return (
      <div className="col-sm-6 col-lg-4 d-flex" key={ `${loan.name}_${loan.id}` }>
        <LoanCard loan={ loan } onSubmit={ loan.isNew() && this.addLoan || this.updateLoan } onDelete={ this.deleteLoan } />
      </div>
    )
  }

  renderLoans() {
    return (
      <div id="loans">
        <div className="row">
          <div className="col-12">
            <div className="big-header">{ this.t('new.header') }</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="text-fit-3 light-blue">{ this.t('new.step2.header') }</div>
          </div>
        </div>
        <div className="row">
          { this.state.loans.map((loan, idx) => this.renderLoan(loan, idx)) }
          { this.renderLoan(new Loan(), this.state.loans.length) }
        </div>

        <div className="row">
          <div className="col">
            <div className="text-fit-3 light-blue">{ this.t('new.step3.header') }</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="card-title loan-name text-fit-2">{ this.t('new.step3.extra_payment_label') }</h5>
                    <form id="extra_payment">
                      <div className="input-group">
                        <input aria-describedby="name" className="form-control text-input text-fit-2" min="0" name="extra_payment" placeholder={ this.t('new.step3.extra_payment_placeholder') } type="number" value={ this.state.extra_payment } onChange={ this.handlePreferencesChange } />
                        <span className="text-fit-2 align-self-center ml-2">kr</span>
                        <div className="card-text my-2 text-muted text-fit-2">{ this.t('new.step3.extra_payment_hint') }</div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6">
                    <h5 className="card-title loan-name text-fit-2">{ this.t('new.step3.count_from_label') }</h5>
                    <form>
                      <select className="custom-select mr-sm-2 text-fit-2 text-dark" name="count_from" value={ this.state.count_from } onChange={ this.handlePreferencesChange }>
                        {
                          Object.entries(this.t('new.step3.count_from_options')).map((entry, idx) => <option value={ entry[0] } key={ idx }>{ entry[1] }</option>)
                        }
                      </select>
                      <div className="card-text my-2 text-muted text-fit-2">{ this.t('new.step3.count_from_hint') }</div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="text-fit-3 text-success">{ this.t('new.step4.header') }</div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <form action="/payoff_plans/generate" acceptCharset="UTF-8" data-remote="true" method="post">
              <input type="hidden" name="authenticity_token" value="zoZAnJzJyC0QK25hcT3WTpM4TbA7aUEHQs9/yk+iFgJr+QMu/SOo24bbLS5zJihqBaL0FGTbSWSPUUycCHctuw==" />
              <div className="btn btn-success btn-block text-fit-3" onClick={ this.generatePlan }>{ this.t('new.step4.submit') }</div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  render () {
    I18n.locale = this.state.lang;

        // { this.renderHeader() }
    return (
      <React.Fragment>
        <div className="page-wrapper">
          <div className="container">
            { this.renderAlerts() }
            {
              this.showOnboarding() && this.renderOnboarding() || this.renderLoans()
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

PayoffPlan.propTypes = {
  formAuthenticityToken: PropTypes.string,
  // callbackUrl: PropTypes.string,
  // locale: PropTypes.string,
}
export default PayoffPlan
