import React from "react"

import I18n from 'packs/i18n/translations';
I18n.translations || (I18n.translations = {});

export const ICONS = {
  mortgage: 'fa-home',
  car: 'fa-car',
  consumer_loan: 'fa-shopping-bag',
  student_loan: 'fa-user-graduate',
  credit_card: 'fa-credit-card',
  other: 'fa-money-bill-alt',
};

class DebtTypeButtonList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.value,
    };
    this.cardClass = props.cardClass || 'col-md-3 col-lg-2 col-6';

    this.selectType = this.selectType.bind(this);
  }

  t (transaction) {
    return I18n.t(`payoff_plans.debt_type_buttons.${transaction}`);
  }

  selectType (event) {
    const value = event.currentTarget.dataset.value;
    this.setState({ selected: value }, () => this.props.onChange && this.props.onChange(value));
  }

  renderCard (value, idx) {
    return (
      <div className={ this.cardClass } key={ idx }>
        <div className={ `card card-button ${(this.state.selected == value) && 'selected' || ''}` } onClick={ this.selectType } data-value={ value }>
          <div className="card-body">
            <h4 className="card-title text-center">{ this.t(value) }</h4>
            <div className="text-center"><i className={ `fa-3x fas ${ICONS[value]}` }></i></div>
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <React.Fragment>
        <label>{ this.t('label') }</label>
        <div className="row form-group">
          { Object.keys(ICONS).map((value, idx) => this.renderCard(value, idx)) }
        </div>
      </React.Fragment>
    )
  }
}

export default DebtTypeButtonList
