import CustomDate from 'date-and-time';

const formatNumber = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

class Loan {
  constructor (attrs = {}) {
    Object.entries(attrs).map(entry => this[entry[0]] = entry[1]);
    // this.id = attrs.id || 0;
    // this.debtType = attrs.debtType || 'credit_card';
    // this.name = attrs.name;
    // this.interestRate = attrs.interestRate;
    // this.totalBalance = attrs.totalBalance;
    // this.payment = attrs.payment;
  }

  parse (attrs) {
    // Flatten the data
    let tempLoan = attrs.loan;
    tempLoan.financialInstitutionName = attrs.creditorName;
    attrs = tempLoan;

    this.id = attrs.id || 0;
    this.debtType = attrs.debtType || attrs.type && attrs.type == 'repaymentLoan' && 'consumer_loan' || 'credit_card';
    this.name = attrs.financialInstitutionName || attrs.name;

    // If info came from Norskgjeld
      // "loan": {
      //     "type": "creditFacility",
      //     "timestamp": "2021-06-25T03:25:03Z",
      //     "coBorrower": 0,
      //     "creditLimit": 1000000,
      //     "installmentCharges": 4500,
      //     "nominalInterestRate": 0,
      //     "interestBearingBalance": 0,
      //     "installmentChargePeriod": "MONTHLY",
      //     "nonInterestBearingBalance": 0
      //   },
      //   "creditorName": "RESURS BANK AB"
    if (attrs.nominalInterestRate !== undefined || attrs.type == "chargeCard") {
      this.nominalInterestRate = Number(attrs.nominalInterestRate || 0) / 100;
      this.interestRate = this.nominalInterestRate;

      if (attrs.balance) {
        this.totalBalance = Number(attrs.balance) / 100;
      } else {
        this.balanceWithoutInterest = formatNumber(parseInt(attrs.nonInterestBearingBalance) / 100);
        this.balanceWithInterest = formatNumber(parseInt(attrs.interestBearingBalance) / 100);
        this.totalBalance = formatNumber(this.balanceWithInterest + this.balanceWithoutInterest);
        // this.totalBalance = this.totalBalance == 0 ? 0 : -this.totalBalance;
      }

      this.lender = {
        name: attrs.financialInstitutionName,
        email: attrs.financialInstitutionEmail,
        orgID: attrs.financialInstitutionID,
        phone: attrs.financialInstitutionPhone,
        url: attrs.financialInstitutionUrl,
      };

      this.charges = parseInt(attrs.installmentCharges || 0) / 100;
      this.period = attrs.installmentChargePeriod || 'MONTHLY';
      this.limit = Number(attrs.creditLimit || attrs.originalBalance || attrs.totalBalance) / 100;
      this.coBorrower = !!parseInt(attrs.coBorrower, 10);
      // this.updatedAt = CustomDate.parse(attrs.receivedTime, 'DD.MM.YYYY hh:mm');
      this.updatedAt = new Date(attrs.timestamp);
    } else {
      this.interestRate = attrs.interestRate;
      this.totalBalance = attrs.totalBalance;
    }

    this.payment = Math.min(formatNumber(Math.max(Math.round(Math.abs(this.totalBalance) * 0.03), 350)), this.totalBalance);
  }

  isValid () {
    // Fields to be present
    let presentFields = ['debtType', 'interestRate', 'payment', 'name', 'totalBalance'];
    for (var i = 0; i < presentFields.length; i++) {
      let dataKey = presentFields[i];
      // console.log(dataKey);
      //Do something
      let value = this[dataKey];

      if (!value && value != 0 || value.length < 1) {
        return false;
      }

      if (['interest_rate', 'minimum_payment', 'current_balance'].includes(dataKey) && Number(value) == NaN) {
        return false;
      }
    }

    return true;
  }

  isNew () { return this.name === undefined }
}

export default Loan
