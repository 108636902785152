// const pageSetEvent = new CustomEvent('pageSet');

import Cookies from 'js-cookie'
import "./multistep_form.js";
import consumer from "channels/consumer";
import sha256 from 'crypto-js/sha256';

const hexHash = (msg) => sha256(msg);

import I18n from './i18n/translations';
I18n.translations || (I18n.translations = {});
I18n.locale = Cookies.get('locale') || 'en';

if (document.querySelector('#main-wrapper.application')) {
  let langSelectorEl = document.querySelector('#lang_select');
  if (langSelectorEl) {
    langSelectorEl.value = I18n.locale;
    langSelectorEl.addEventListener('change', (event) => {
      Cookies.set('locale', event.target.value);
      window.location.reload();
    })
  }

  ////////////////////////////////////////////////////////////////////////
  // Store all data locally
  ////////////////////////////////////////////////////////////////////////

  let dataKey = 'goscoreDebtfree',
      extraPaymentKey = `${dataKey}ExtraPayment`;

  let getLoansData = () => {
    return localStorage.getItem(dataKey) && JSON.parse(localStorage.getItem(dataKey));
  };

  let saveLoansData = (loansData) => {
    localStorage.setItem(dataKey, JSON.stringify(loansData));
  };

  let validateLoanData = (loanData) => {
    // Fields to be present
    let presentFields = ['debt_type', 'interest_rate', 'minimum_payment', 'name', 'current_balance'];
    for (var i = 0; i < presentFields.length; i++) {
      let dataKey = presentFields[i];
      // console.log(dataKey);
      //Do something
      let value = loanData[dataKey];

      if (!value || value.length < 1) {
        return false;
      }

      if (['interest_rate', 'minimum_payment', 'current_balance'].includes(dataKey) && Number(value) == NaN) {
        return false;
      }
    }

    return true;
  };

  let addOrUpdateLoan = (loanData) => {
    if (!validateLoanData(loanData)) { return false; }

    let allLoans = getLoansData();

    if (allLoans) {
      if (loanData.id) {
        // console.log('updateed');
        allLoans.loans[Number(loanData.id)] = loanData;
      } else {
        loanData.id = allLoans.loans.length;
        allLoans.loans.push(loanData);
        allLoans.latest_id = allLoans.latest_id + 1;
      }
      allLoans.updated_at = (new Date()).getTime();

      saveLoansData(allLoans);
    } else {
      loanData.id = 0;
      saveLoansData({
        created_at: (new Date()).getTime(),
        updated_at: (new Date()).getTime(),
        latest_id: loanData.id,
        loans: [loanData],
      })
    }

    return true;
  };

  let destroyLoan = (loanId) => {
    if (window.confirm(I18n.t('payoff_plans.new.destroy_confirm'))) {
      let allLoans = getLoansData();

      if (allLoans) {
        delete allLoans.loans[Number(loanId)];
        allLoans.loans = allLoans.loans.filter(Boolean);

        saveLoansData(allLoans);
        showLoans();
      }
    }
  }

  let saveExtraPayment = () => {
    const value = document.querySelector('input[name="extra_payment"]').value;
    if (!value || Number(value) == NaN || Number(value) < 0 ) { return false; }

    localStorage.setItem(extraPaymentKey, value);
    return true;
  };


  let setExtraPayment = () => {
    document.querySelector('input[name="extra_payment"]').value = localStorage.getItem(extraPaymentKey);
  };

  ////////////////////////////////////////////////////////////////////////
  // FormData to map
  ////////////////////////////////////////////////////////////////////////

  let nextButtonSel = '.js-btn-next', ourNextButtonSel = '.btn-step-next';
  let formDataToMap = (data) => {
    return Array.from(data.entries()).reduce(function(map, obj) {
      map[obj[0]] = obj[1];
      return map;
    }, {});
  };

  let goNext = (el) => {
    let multiStepForm = el.closest(`.multisteps-form__content`);
    if (multiStepForm) { multiStepForm.querySelector(nextButtonSel).click() };
  }

  ////////////////////////////////////////////////////////////////////////
  // Check if user already saved any loan
  ////////////////////////////////////////////////////////////////////////

  let onboardingWrapper = document.querySelector('#onboarding_wrapper');
  let loansWrapper = document.querySelector('#loans_wrapper');
  let loanTypeSelector = 'input[name="debt_type"]';

  let selectCards = (scope) => {
    let radioInputs = scope.querySelectorAll(loanTypeSelector);
    radioInputs.forEach((radioInput) => {
      let card = radioInput.closest('.card');
      if (radioInput.checked) {
        card.classList.add('selected');
      } else {
        card.classList.remove('selected');
      }
    });
  };

  let checkCardButton = (cardButton) => {
    let debtTypeInput = cardButton.querySelector(loanTypeSelector);
    // console.log(debtTypeInput);
    debtTypeInput.checked = true;

    // let data = new FormData(firstDebtForm);
    // console.log(formDataToMap(data));

    // goNext(cardButton);
    let multiStepForm = cardButton.closest(`.multisteps-form__content`);
    if (multiStepForm) {
      multiStepForm.querySelector('#loan_name').classList.remove('d-none');
      window.dispatchEvent(new Event('resize'));
    };
  }

  ////////////////////////////////////////////////////////////////////////
  // Onboarding
  ////////////////////////////////////////////////////////////////////////

  let checkFormElement = () => {
    // Check the height
    setTimeout(() => {
      // console.log('here', document.querySelector('#first_debt').offsetHeight);
      if (document.querySelector('#first_debt').offsetHeight == 0) {
        window.dispatchEvent(new Event('resize'));
        checkFormElement();
      }
    }, 100);
  }

  let checkOnboarding = () => {
    if (getLoansData()) {
      onboardingWrapper.classList.add('d-none');
      showLoans();
      loansWrapper.classList.remove('d-none');
    } else {
      loansWrapper.classList.add('d-none');
      onboardingWrapper.classList.remove('d-none');
      checkFormElement();
    }
  }

  let displayName = () => {}

  let firstDebtForm = document.querySelector('form#first_debt');
  if (firstDebtForm) {
    // let stepContentClass = 'multisteps-form__content';
    let cardButtons = document.querySelectorAll('.card-button');

    // if (cardButtons) {
    //   cardButtons.forEach((cardButton) => {
    //     cardButton.addEventListener('click', (event) => {
    //       checkCardButton(cardButton);
    //       // let debtTypeInput = cardButton.querySelector(loanTypeSelector);
    //       // console.log(debtTypeInput);
    //       // debtTypeInput.checked = true;

    //       // // let data = new FormData(firstDebtForm);
    //       // // console.log(formDataToMap(data));

    //       // goNext(cardButton);
    //     });
    //   });
    // }

    firstDebtForm.addEventListener('submit', (event) => {
      event.preventDefault();

      let data = formDataToMap(new FormData(firstDebtForm));
      addOrUpdateLoan(data);
      // console.log(data);

      let successNotification = document.querySelector('#first_step_completed');
      successNotification.classList.add('show');
      successNotification.classList.remove('d-none');

      checkOnboarding();
    });

    // Set name when go to the next step
    firstDebtForm.querySelectorAll(ourNextButtonSel).forEach((nextBtn) => {
      nextBtn.addEventListener('click', (e) => {
        // Set loan name as a title
        firstDebtForm.querySelectorAll('h5.card-title.loan-name').forEach((loanNameTitle) => {
          // loanNameTitle.textContent = `Your loan: ${firstDebtForm.querySelector('input[name="name"]').value}`;
          loanNameTitle.textContent = I18n.t('payoff_plans.first_time_form.loan_name', { name: firstDebtForm.querySelector('input[name="name"]').value });
        });

        // Check is field is set
        let stepInput = nextBtn.closest('.row.form-group').querySelector('input');
        if (stepInput.value.length < Number(stepInput.dataset.minLength) || (stepInput.type == 'number' && Number(stepInput.value) == NaN)) {
          console.log('nie mozhna tak!');
        } else {
          nextBtn.nextSibling.click();
        };
      })
    });
  }

  const collectDebtId = 'collect_bankid';
  let collectDebt = document.getElementById(collectDebtId);

  if (collectDebt) {
    collectDebt.addEventListener('click', (event) => {
      event.preventDefault();

      let phone = document.querySelector('input[name="payoff_plan[bankid_phone]"]').value,
          birthDate = document.querySelector('input[name="payoff_plan[bankid_birth_date]"]').value;

      // Wait for reference code
      let bankIdSubscription =
        // consumer.subscriptions.create({ channel: "BankIdChannel", birth_date: birthDate, phone_number: phone }, {
        consumer.subscriptions.create({ channel: "BankIdChannel", id: String(hexHash(`${birthDate}_${phone}`)) }, {
          received(data) {
            // alert(data.body);
            let wrapper = document.getElementById('bankid_reference_code_wrapper'),
                code = document.getElementById('bankid_reference_code');
            code.textContent = data.body;
            wrapper.classList.remove('d-none');
          }
        });

      fetch('/payoff_plans/debt_info', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          authenticity_token: document.querySelector("input[name='auth_token']").value,
          payoff_plan: {
            phone: phone,
            birth_date: birthDate,
          }
        })
      }).then(respJSON => {
        // consumer.subscriptions.remove(bankIdSubscription);
        bankIdSubscription.unsubscribe();
        consumer.disconnect();

        if (!respJSON.ok) {
          throw Error(respJSON.statusText);
        }
        // console.log(respJSON);

        return respJSON.json();
      })
      .then(resp => {
        if (resp.error_message) {
          throw Error(resp.error_message);
        }

        // Loan item sample:
        //    coBorrower: "0"
        //    creditLimit: "2500000"
        //    financialInstitutionEmail: "kundeservice.kredittkort@sparebank1.no"
        //    financialInstitutionID: "975966453"
        //    financialInstitutionName: "SPAREBANK 1 KREDITT AS"
        //    financialInstitutionPhone: ""
        //    financialInstitutionUrl: ""
        //    installmentChargePeriod: "MONTHLY"
        //    installmentCharges: "0"
        //    interestBearingBalance: "569385"
        //    nominalInterestRate: "2162"
        //    nonInterestBearingBalance: "113130"
        //    processedTime: "06.07.2020 05:36"
        //    providerID: "9908:975966453"
        //    receivedTime: "06.07.2020 07:16"
        const loans = resp.list.filter((item) => item.type === "creditFacility")[0].loans;
        loans.map((loan) => {
          const currentBalance = (Number(loan.interestBearingBalance) + Number(loan.nonInterestBearingBalance)) / 100;

          addOrUpdateLoan({
            debt_type: 'credit_card',
            name: loan.financialInstitutionName,
            current_balance: currentBalance,
            interest_rate: Number(loan.nominalInterestRate) / 100,
            minimum_payment: Math.max(0.03 * currentBalance, 350),
          })
        })

        let successNotification = document.querySelector('#first_step_completed');
        successNotification.classList.add('show');
        successNotification.classList.remove('d-none');

        checkOnboarding();
      })
      .catch(error => alert(error));
    });
  };

  ////////////////////////////////////////////////////////////////////////
  // Loans
  ////////////////////////////////////////////////////////////////////////

  let modalLoanForms = document.querySelectorAll('.loan-modal form');
  let addLoanEl = document.createElement('span');
  addLoanEl.innerHTML = `<a class="text-center col-sm-6 col-lg-4 d-flex py-0" data-target="#add_loan_modal" data-toggle="modal">
      <div class="loan card align-self-stretch flex-fill">
          <div class="card-body">
              <div class="text-center my-4"><i class="fa-5x fas fa-plus-circle light-blue"></i></div>
              <div class="text-center my-4 text-fit-3">${I18n.t('payoff_plans.new.add_new')}</div>
          </div>
      </div>
  </a>`;
  addLoanEl = addLoanEl.firstChild;

  let modalFormClone = document.querySelector('#add_loan_modal') && document.querySelector('#add_loan_modal').cloneNode(true);
  let loansEl = document.querySelector('#loans');

  let loanIcon = (loan) => {
    let loanIcon;

    switch (loan.debt_type) {
      case 'consumer_loan':
        loanIcon = 'shopping-bag';
        break;
      case 'mortgage':
        loanIcon = 'home';
        break;
      case 'credit_card':
        loanIcon = 'credit-card';
        break;
      case 'car':
        loanIcon = 'car';
        break;
      case 'student_loan':
        loanIcon = 'user-graduate';
        break;
      case 'other':
        loanIcon = 'money-bill-alt';
        break;
      default:
        loanIcon = 'shopping-bag';
    }

    return loanIcon;
  }

  let showLoans = () => {
    let loans = getLoansData().loans;

    if (loansEl && loans) {
      loansEl.innerHTML = '';
      loans.forEach((loan, idx) => {
        let colEl = document.createElement("div");
        const formId = `loan_modal_${idx}`;

        modalFormClone.id = formId;
        modalFormClone.querySelector('input[name="id"]').value = idx;
        modalFormClone.querySelector('form').id = `loan_${idx}`;
        modalFormClone.querySelector('.modal-title').textContent = I18n.t('payoff_plans.update.modal_form.header');
        modalFormClone.querySelector('.submit-modal-form').textContent = I18n.t('payoff_plans.update.modal_form.save_button');

        colEl.innerHTML = `<div class="col-sm-6 col-lg-4 d-flex">
              <div class="loan card align-self-stretch flex-fill">
                <div class="destroy-loan" data-loan-id=${idx}><i class="fas fa-times fa-2x"></i></div>
                <div class="card-body">
                  <div class="text-center my-4"><i class="fa-5x fas fa-${loanIcon(loan)} light-blue"></i></div>
                  <div class="text-center my-4 text-fit-3">${loan.name}</div>
                  <ul class="list-style-none mb-0">
                    <li><i class="fas fa-circle text-primary font-10 mr-2"></i><span class="text-muted">${I18n.t('payoff_plans.balance')}</span><span class="text-dark float-right font-weight-medium">${loan.current_balance} kr</span></li>
                    <li class="mt-2"><i class="fas fa-circle text-danger font-10 mr-2"></i><span class="text-muted">${I18n.t('payoff_plans.interest_rate')}</span><span class="text-dark float-right font-weight-medium">${loan.interest_rate} %</span></li>
                    <li class="mt-2"><i class="fas fa-circle text-cyan font-10 mr-2"></i><span class="text-muted">${I18n.t('payoff_plans.min_payment')}</span><span class="text-dark float-right font-weight-medium">${loan.minimum_payment} kr</span></li>
                  </ul>
                </div>

                ${modalFormClone.outerHTML}
              </div>
            </div>`;

        loansEl.appendChild(colEl.firstChild);
        // Preset values
        let loanForm = loansEl.querySelector(`#${formId}`);
        let debtTypeEl = loanForm.querySelector(`input[value=${loan.debt_type}]`);
        if (debtTypeEl) { debtTypeEl.checked = true; };
        selectCards(loanForm);
        ['current_balance', 'interest_rate', 'minimum_payment', 'name'].forEach((key) => {
          // modalFormClone.querySelector(`input[name=${key}]`).value = Number(loan[key]);
          loansEl.querySelector(`#${formId} input[name="${key}"]`).value = loan[key];
        });
      });
      loansEl.appendChild(addLoanEl);

      // Restore extra payment settings
      setExtraPayment();
    }
  }

  // modalLoanForms.forEach((modalLoanForm) => {
  //   modalLoanForm.querySelectorAll('.card-button').forEach((card) => {
  //     card.addEventListener('click', () => {
  //       selectCards(modalLoanForm);
  //     });
  //   });
  // });

  ////////////////////////////////////////////////////////////////////////
  // Add new loan
  ////////////////////////////////////////////////////////////////////////

  // document.querySelectorAll('.submit-modal-form').forEach((button) => {
  // });
  document.querySelector('#add_loan_modal .submit-modal-form').addEventListener('click', (event) => {
    event.preventDefault();

    let modal = event.target.closest('.modal')
    let modalForm = modal.querySelector('form');
    let data = formDataToMap(new FormData(modalForm));
    if (addOrUpdateLoan(data)) {
      // console.log(data);
      showLoans();

      $(modal).modal('hide');
      modalForm.reset();
      selectCards(modalForm);
    } else {
      alert(I18n.t('payoff_plans.new.errors.field_missed'));
    }
  });

  loansEl && loansEl.addEventListener('click', (event) => {
    let loanCardBody = event.target.closest('.loan.card .card-body');
    let loanCard = event.target.closest('.loan.card');
    let destroyButton = event.target.closest('.destroy-loan');

    // Detroy loan x
    if (destroyButton) {
      destroyLoan(destroyButton.dataset.loanId);
    } else {
      // Open modal on click
      if (loanCardBody) {
        let modal = loanCard.closest('.loan.card').querySelector('.loan-modal');
        if (modal && !modal.classList.contains('show')) { $(modal).modal('show') };
      }
    }

    // Submit loan modal form
    if (event.target.classList.contains('submit-modal-form')) {
      event.preventDefault();

      let modal = event.target.closest('.loan-modal')
      let modalForm = modal.querySelector('form');
      let data = formDataToMap(new FormData(modalForm));

      if (addOrUpdateLoan(data)) {
        // console.log(data);
        showLoans();

        $(modal).modal('hide');
        modalForm.reset();
      } else {
        alert(I18n.t('payoff_plans.new.errors.field_missed'));
      }
    }
  });

  document.addEventListener('click', (event) => {
    // Select debt type from big cards
    let cardButton = event.target.closest('.card-button');
    let modal = event.target.closest('.modal');
    let stepFormContent = event.target.closest('.multisteps-form__content');

    if (cardButton) {
      checkCardButton(cardButton);
      selectCards(modal || stepFormContent);
    }
  })

  // document.querySelectorAll('.loan-form').forEach((loanForm) => {
  //   loanForm.addEventListener('submit', (event) => {
  //     event.preventDefault();

  //     let data = formDataToMap(new FormData(firstDebtForm));
  //     addOrUpdateLoan(data);
  //     console.log(data);

  //     let modal = loanForm.closest('.modal');
  //     if (modal) { $(modal).modal('hide') };
  //   });
  // });

  ////////////////////////////////////////////////////////////////////////
  // Setup page
  ////////////////////////////////////////////////////////////////////////
  checkOnboarding();

  ////////////////////////////////////////////////////////////////////////
  // Get payoff plan
  ////////////////////////////////////////////////////////////////////////
  let getPlanButton = document.querySelector('#get_plan');
  getPlanButton.addEventListener('click', (event) => {
    let extraMoney = document.querySelector('form#extra_payment input[name="extra_payment"]').value;
    let countFrom = document.querySelector('form select[name="count_from"]').value;
    let authToken = document.querySelector('input[name="authenticity_token"]').value;
    let requestParams = {
      loans: getLoansData().loans,
      extra_payment: extraMoney,
      authenticity_token: authToken,
      count_from: countFrom,
    }

    // Save extra payment
    if (saveExtraPayment()) {
      fetch('/payoff_plans/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestParams),
      }).then((resp) => {
        // console.log(resp);
        if (!resp.ok) {
          if (resp.status == 422) {
            throw Error(I18n.t('payoff_plans.new.errors.infeasible_data'));
          } else {
            throw Error(resp.statusText);
          };
        };

        // return resp.blob();
        return resp.json();
      }).then((response) => {
        // let a = document.createElement('a');
        // let url = window.URL.createObjectURL(data);

        // a.href = url;
        // a.download = 'my_personal_payoff_plan.xlsx';
        // a.click();
        // window.URL.revokeObjectURL(url);

        // let fileBlob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // window.open(fileBlob, '_blank');

        // window.open(response.url, '_blank');

        // let a = document.createElement('a');
        // a.href = response.url;
        // a.target = '_blank';
        // a.click();

        location.href = response.url;
      }).catch(function(error) {
        alert(error.message);
      });
    } else {
      alert(I18n.t('payoff_plans.new.errors.no_extra_payment_set'));
    }
  });
}
