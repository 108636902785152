import React from "react"
import PropTypes from "prop-types"

import Loan from "./models/Loan";
import DebtTypeButtonList, { ICONS } from './DebtTypeButtonList'

import CloseImg from 'images/close.svg'

import I18n from 'packs/i18n/translations';
// I18n.translations || (I18n.translations = {});

let formDataToMap = (data) => {
  return Array.from(data.entries()).reduce(function(map, obj) {
    map[obj[0]] = obj[1];
    return map;
  }, {});
};

class LoanCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loan: props.loan,
    };

    this.deleteLoan = this.deleteLoan.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.showModal = this.showModal.bind(this);

    this.modalForm = React.createRef();
    this.debtTypeSelect = React.createRef();
  }

  t (translation) {
    return I18n.t(`payoff_plans.${translation}`);
  }

  showModal () {
    $(this.modalForm.current).modal('show');
  }

  hideModal () {
    $(this.modalForm.current).modal('hide');
  }

  onFormSubmit (event) {
    event.preventDefault();
    const formData = formDataToMap(new FormData(event.currentTarget));
    formData.debtType = this.debtTypeSelect.current.state.selected;

    this.hideModal();

    this.props.onSubmit(Object.assign(this.state.loan, formData));
  }

  deleteLoan (event) {
    event.preventDefault();
    event.stopPropagation();
    console.log(this.state.loan.id);

    window.confirm("Are you sure you wish to delete this loan?") && this.props.onDelete(this.state.loan.id);
  }

  renderModalForm (loan) {
    return (
      <form className="loan-form" onSubmit={ this.onFormSubmit }>
        <div aria-hidden="true" aria-labelledby="fullWidthModalLabel" className="modal fade loan-modal" id="loan_modal_0" role="dialog" tabIndex="-1" ref={ this.modalForm }>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-full-width">
            <div className="modal-content container">
              <div className="modal-header">
                <h4 className="modal-title big-header" id="fullWidthModalLabel">{ loan.isNew() && this.t('new.add_new') || this.t('update.modal_form.header') }</h4>
                <a aria-hidden="true" className="close show-pointer" data-dismiss="modal">
                  <img src={ CloseImg } />
                </a>
              </div>
              <div className="modal-body">
                <DebtTypeButtonList value={ loan.debtType } ref={ this.debtTypeSelect } />

                <div className="row form-group">
                  <div className="col-md-6 mb-2">
                    <label>{ this.t('new.modal_form.name') }</label>
                    <input className="form-control text-input text-fit-2" name="name" placeholder="COOP mastercard" type="text" defaultValue={ loan.name } />
                  </div>
                  <div className="col-md-6 mb-2">
                    <label>{ this.t('new.modal_form.balance') }</label>
                    <div className="input-group">
                      <input className="form-control text-input text-fit-2" name="totalBalance" placeholder="46890" type="number" step="0.01" defaultValue={ loan.totalBalance } />
                      <span className="text-fit-2 align-self-center ml-2">kr</span>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label>{ this.t('new.modal_form.interest_rate') }</label>
                    <div className="input-group">
                      <input className="form-control text-input text-fit-2" name="interestRate" placeholder="8.99" step="any" type="number" defaultValue={ loan.interestRate } />
                      <span className="text-fit-2 align-self-center ml-2">%</span>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label>{ this.t('new.modal_form.min_payment') }</label>
                    <div className="input-group">
                      <input className="form-control text-input text-fit-2" name="payment" placeholder="450" type="number" defaultValue={ loan.payment } />
                      <span className="text-fit-2 align-self-center ml-2">kr</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer"><input type="submit" className="btn btn-outline-success btn-block submit-modal-form text-fit-3 text-uppercase" value={ loan.isNew() && this.t('new.modal_form.save_button') || this.t('update.modal_form.save_button') } /></div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  render() {
    let loan = new Loan(this.state.loan);

    return (
      <div className="loan card align-self-stretch flex-fill" onClick={ this.showModal }>
        <div className="destroy-loan" data-loan-id="0" onClick={ this.deleteLoan } ><i className="fas fa-times fa-2x"></i></div>
        <div className="card-body">
          <div className="text-center my-4"><i className={ `fa-5x fas ${ICONS[loan.debtType] || 'fa-plus-circle'} light-blue` }></i></div>
          <div className="text-center my-4 text-fit-3">{ loan.name || this.t('new.add_new') }</div>
          <ul className="list-style-none mb-0">
            { (loan.totalBalance || loan.totalBalance == 0) && <li><i className="fas fa-circle text-primary font-10 mr-2"></i><span className="text-muted">{ this.t('balance') }</span><span className="text-dark float-right font-weight-medium">{ loan.totalBalance } kr</span></li> }
            { !!loan.interestRate && <li className="mt-2"><i className="fas fa-circle text-danger font-10 mr-2"></i><span className="text-muted">{ this.t('interest_rate') }</span><span className="text-dark float-right font-weight-medium">{ loan.interestRate } %</span></li> }
            { (loan.payment || loan.payment == 0) && <li className="mt-2"><i className="fas fa-circle text-cyan font-10 mr-2"></i><span className="text-muted">{ this.t('min_payment') }</span><span className="text-dark float-right font-weight-medium">{ loan.payment } kr</span></li> }
          </ul>
        </div>
        { this.renderModalForm(loan) }
      </div>
    )
  }
}

LoanCard.propTypes = {
  lang: PropTypes.string,
  loan: PropTypes.object,
}
export default LoanCard
